document.addEventListener('DOMContentLoaded', function() {
  var MOBILE_WIDTH = 640;
  var nav = document.getElementById('nav');
  var ham = document.getElementById('hamburger');
  var body = document.getElementsByTagName('body')[0];

  function hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
  }

  ham.addEventListener('click', function(e) {
    if (hasClass(nav, 'open')) {
      nav.className = nav.className.replace(' open', '');
      body.className = body.className.replace(' nav-open', '');
    } else {
      var doc = document.documentElement;
      var top = (window.pageYOffset || doc.scrollTop);
      if (top > 0) {
        Pangea.scrollTo(0, 200, function() {
          nav.className += ' open';
          body.className += ' nav-open';
        });
      } else {
        nav.className += ' open';
        body.className += ' nav-open';
      }
    }
  });
});
